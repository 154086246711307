// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-studio-js": () => import("/opt/build/repo/src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-studio-year-2015-js": () => import("/opt/build/repo/src/pages/studio/year-2015.js" /* webpackChunkName: "component---src-pages-studio-year-2015-js" */),
  "component---src-pages-studio-year-2016-js": () => import("/opt/build/repo/src/pages/studio/year-2016.js" /* webpackChunkName: "component---src-pages-studio-year-2016-js" */),
  "component---src-pages-studio-year-2017-js": () => import("/opt/build/repo/src/pages/studio/year-2017.js" /* webpackChunkName: "component---src-pages-studio-year-2017-js" */),
  "component---src-pages-studio-year-2018-js": () => import("/opt/build/repo/src/pages/studio/year-2018.js" /* webpackChunkName: "component---src-pages-studio-year-2018-js" */),
  "component---src-pages-studio-year-2019-js": () => import("/opt/build/repo/src/pages/studio/year-2019.js" /* webpackChunkName: "component---src-pages-studio-year-2019-js" */),
  "component---src-pages-works-js": () => import("/opt/build/repo/src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

